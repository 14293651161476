import { FETCH_PAYOUT_DATA, FETCH_PAYOUT_DATA_LOADING } from "./actionTypes";

const initialState = {
  loading: false,
  payouts: [],
  totalSize: 0,
};

const payoutReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PAYOUT_DATA_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_PAYOUT_DATA:
      return {
        ...state,
        payouts: payload.rows || [],
        totalSize: payload.count || 0,
      };

    default:
      return state;
  }
};

export default payoutReducer;
