import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const { footerMenus } = useSelector(state => state.auth);
  return (
    <footer>
      <span>
        <i className="fa fa-copyright" />
        2022 Stuff You Can Use
      </span>
      {!!footerMenus &&
        footerMenus.length > 0 &&
        footerMenus.map(menu => (
          <React.Fragment key={menu.application_menu_id}>
            {" "}
            | <Link to={menu.link}>{menu.application_menu_title}</Link>
          </React.Fragment>
        ))}
    </footer>
  );
};

export default Footer;
