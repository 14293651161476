import React from "react";
import { useSelector } from "react-redux";
import { get, remove, set } from "services/cookies";

import Footer from "./Footer";
import Header from "./Header";

const RightBar = ({ children }) => {
  const { toggleMenu } = useSelector(state => state.auth);

  const onClose = () => {
    const mainToken = get("mainToken");
    set("token", mainToken);
    remove("mainToken");
    remove("userId");
    remove("site");
    window.location.replace(process.env.REACT_APP_ADMIN_SITE_URL);
  };

  return (
    <>
      <div className={`main-content ${toggleMenu ? "toggle" : ""}`}>
        {!!get("mainToken") && window.location === window.parent.location && (
          <div className="bg-top-bar d-flex align-items-center justify-content-end p-3">
            <button
              className="btn bg-white text-top-bar d-flex align-items-center rounded-pill px-3 frame-close-btn shadow-lg "
              onClick={onClose}
            >
              <i className="fas fa-arrow-left me-2" />{" "}
              <span>Back To Dashboard</span>
            </button>
          </div>
        )}
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default RightBar;
