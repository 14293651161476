export const ReferralCounts = {
  affiliates: 0,
  payouts: 0,
  referrals: 0,
  visits: 0,
};
export const ConversationGraph = [
  {
    month: "Jan",
    visit: 0,
    referral: 0,
  },
  {
    month: "Feb",
    visit: 0,
    referral: 0,
  },
  {
    month: "Mar",
    visit: 0,
    referral: 0,
  },
  {
    month: "Apr",
    visit: 0,
    referral: 0,
  },
  {
    month: "May",
    visit: 0,
    referral: 0,
  },
  {
    month: "Jun",
    visit: 0,
    referral: 0,
  },
  {
    month: "Jul",
    visit: 0,
    referral: 0,
  },
  {
    month: "Aug",
    visit: 0,
    referral: 0,
  },
  {
    month: "Sep",
    visit: 0,
    referral: 0,
  },
  {
    month: "Oct",
    visit: 0,
    referral: 0,
  },
  {
    month: "Nov",
    visit: 0,
    referral: 0,
  },
  {
    month: "Dec",
    visit: 0,
    referral: 0,
  },
];
export const VisitGraph = [
  {
    month: "Jan",
    count: 0,
  },
  {
    month: "Feb",
    count: 0,
  },
  {
    month: "Mar",
    count: 0,
  },
  {
    month: "Apr",
    count: 0,
  },
  {
    month: "May",
    count: 0,
  },
  {
    month: "Jun",
    count: 0,
  },
  {
    month: "Jul",
    count: 0,
  },
  {
    month: "Aug",
    count: 0,
  },
  {
    month: "Sep",
    count: 0,
  },
  {
    month: "Oct",
    count: 0,
  },
  {
    month: "Nov",
    count: 0,
  },
  {
    month: "Dec",
    count: 0,
  },
];

export const ReferralGraph = [
  {
    month: "Jan",
    referral: 0,
  },
  {
    month: "Feb",
    referral: 0,
  },
  {
    month: "Mar",
    referral: 0,
  },
  {
    month: "Apr",
    referral: 0,
  },
  {
    month: "May",
    referral: 0,
  },
  {
    month: "Jun",
    referral: 0,
  },
  {
    month: "Jul",
    referral: 0,
  },
  {
    month: "Aug",
    referral: 0,
  },
  {
    month: "Sep",
    referral: 0,
  },
  {
    month: "Oct",
    referral: 0,
  },
  {
    month: "Nov",
    referral: 0,
  },
  {
    month: "Dec",
    referral: 0,
  },
];
