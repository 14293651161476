import Loader from "common/Loader";
import React, { Suspense } from "react";
import LeftBar from "./components/LeftBar";
import RightBar from "./components/RightBar";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <LeftBar />
      <RightBar>
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </RightBar>
    </React.Fragment>
  );
};

export default Layout;
