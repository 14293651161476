import { DISPLAY_REFERRALS, DISPLAY_REFERRALS_LOADING } from "./actionTypes";
import { List_REFERRAL_DATA } from "constants/urls";
import { axiosAdmin } from "services/api";
import { toast } from "react-toastify";

export const fetchReferral = (data, cb) => {
  return async dispatch => {
    try {
      dispatch(displayReferralsLoading(true));
      const res = await axiosAdmin.post(List_REFERRAL_DATA, data);
      if (res.status) {
        if (res.data.data) {
          dispatch(displayReferrals(res.data.data));
        }
        dispatch(displayReferralsLoading(false));
        if (!!cb && typeof cb === "function") cb();
      }
    } catch (err) {
      toast(err.response?.data?.message || err.message);
      if (!!cb && typeof cb === "function") cb();
    }
  };
};

export const displayReferralsLoading = data => ({
  type: DISPLAY_REFERRALS_LOADING,
  payload: data,
});

export const displayReferrals = data => ({
  type: DISPLAY_REFERRALS,
  payload: data,
});
