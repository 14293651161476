import { DISPLAY_VISIT_DATA, DISPLAY_VISIT_DATA_LOADING } from "./actionTypes";

const initialState = {
  loading: false,
  visits: [],
  totalSize: 0,
};

const visitReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DISPLAY_VISIT_DATA_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case DISPLAY_VISIT_DATA:
      return {
        ...state,
        visits: payload?.rows || [],
        totalSize: payload?.count || 0,
      };

    default:
      return state;
  }
};

export default visitReducer;
