import { applyMiddleware, compose, createStore } from 'redux';

import thunk from 'redux-thunk';

import rootReducer from './reducers';

const reduxDevToolsExtension = process.env.NODE_ENV !== "production" && (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25,
  }))
const composeEnhancers =
reduxDevToolsExtension ||
  compose;

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
