import React, { useMemo } from "react";
import Logo from "assets/images/Logo.png";
import SmallLogo from "assets/images/grow.png";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToggleMenu } from "store/actions";

const MenuLink = ({ menu, toggleMenu }) => {
  const isCurrentSiteUrl =
    menu.link.startsWith(process.env.REACT_APP_AFFILIATE) ||
    !menu.link.startsWith("http");

  const menuLink = useMemo(() => {
    const link = menu.link.replace(process.env.REACT_APP_AFFILIATE, "");
    if (link.startsWith("/")) return link;
    else return `/${link}`;
  }, [menu.link]);

  return isCurrentSiteUrl ? (
    <NavLink exact to={menuLink}>
      <img src={menu.icon} alt="menu icon" />
      {!toggleMenu ? <span>{menu.application_menu_title}</span> : ""}
    </NavLink>
  ) : (
    <a href={menu.link}>
      {" "}
      <img src={menu.icon} alt="menu icon" />
      {!toggleMenu ? <span>{menu.application_menu_title}</span> : ""}
    </a>
  );
};
const LeftBar = () => {
  const dispatch = useDispatch();

  const { toggleMenu, sideMenus } = useSelector(state => state.auth);

  return (
    <div>
      <div className={`side-nav ${toggleMenu ? "toggle" : ""} `}>
        <div className="logo_header">
          <img src={toggleMenu ? SmallLogo : Logo} alt="logo" />{" "}
          <i
            className="fa-solid fa-xmark cursor-pointer"
            onClick={() => dispatch(setToggleMenu())}
          />
        </div>

        <ul>
          {!!sideMenus && sideMenus.length > 0 ? (
            <>
              {sideMenus.map((menu, index) => (
                <li key={menu.application_menu_id}>
                  <MenuLink menu={menu} toggleMenu={toggleMenu} />
                </li>
              ))}
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
    </div>
  );
};

export default LeftBar;
