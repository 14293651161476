import axios from "axios";
import { LOG_OUT_USER } from "constants/urls";
import store from "store";
import { logout } from "store/actions";
import { clear, get, set } from "./cookies";

const API_URL_ACCOUNT = `${process.env.REACT_APP_API_ACCOUNT_URL}/api/v1`;
const API_URL_ADMIN = `${process.env.REACT_APP_API_ADMIN_URL}/api/v1`;
// const API_URL_ADMIN = `http://192.168.1.116:8081/api/v1`;
// const API_URL_ACCOUNT = `http://192.168.1.74:8081/api/v1`;
const axiosAdmin = axios.create({
  baseURL: API_URL_ADMIN,
});
const axiosAccount = axios.create({
  baseURL: API_URL_ACCOUNT,
});

const non_auth_route = [LOG_OUT_USER];
const requestMiddleware = req => {
  if (!non_auth_route.includes(req.url)) {
    const token = get("token");

    if (!!token) {
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
    }
  }
  return req;
};

const responseMiddleware = response => {
  if (response?.data?.data?.token) {
    set("token", response.data.data.token);
  }
  return response;
};

const responseErr = error => {
  if (error.response.status === 401) {
    clear();
    store.dispatch(logout());
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

axiosAdmin.interceptors.request.use(requestMiddleware);
axiosAccount.interceptors.request.use(requestMiddleware);

axiosAdmin.interceptors.response.use(responseMiddleware, responseErr);
axiosAccount.interceptors.response.use(responseMiddleware, responseErr);

export { axiosAdmin, axiosAccount };
