import { LiveChatWidget } from "@livechat/widget-react";
import "assets/css/custom.scss";
import { GET_DRIFT_DATA } from "constants/urls.js";
import queryString from "query-string";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import privateRoutes from "routes/PrivateRoutes";
import { axiosAccount } from "services/api.js";
import {
  get,
  //  remove,
  set,
} from "services/cookies.js";
import {
  fetchAccessToken,
  fetchMenus,
  fetchProfile,
  setIsAuth,
  setLoading,
  setToken,
  verifyAffiliateUser,
} from "store/actions";
import Layout from "./common/Layout/index.js";

function App() {
  const { isAuth, token, affiliateUser } = useSelector(state => state.auth);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const qry = queryString.parse(location.search);
  const tempToken = qry?.token;
  const userToken = qry?.userToken;

  const pageUrl = location.pathname;

  useEffect(() => {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "page_view", { page_path: window.location.href });
  }, [pageUrl]);

  useLayoutEffect(() => {
    dispatch(setLoading(true));
    const token = get("token");
    if (!isAuth && !!token) {
      dispatch(setToken(token));
      dispatch(fetchProfile());
      dispatch(setIsAuth(true));
    }
    dispatch(setLoading(false));
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (token) {
      dispatch(verifyAffiliateUser());
      let sidePayload = {
        site_id: 8,
        menu_type: 3,
      };
      let footerPayload = {
        site_id: 8,
        menu_type: 2,
      };
      let rightSidePayload = {
        site_id: 1,
        menu_type: 4,
      };
      dispatch(fetchMenus(sidePayload));
      dispatch(fetchMenus(footerPayload));
      dispatch(fetchMenus(rightSidePayload));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!affiliateUser.is_affiliate_user) {
      setIsOpen(true);
    }
  }, [affiliateUser]);

  useEffect(() => {
    if (tempToken) {
      dispatch(setLoading(true));
      set("site_id", 7);
      dispatch(
        fetchAccessToken({ temp_token: tempToken, site_id: 7 }, () => {
          history.replace("/");
          dispatch(setLoading(false));
        })
      );
    }
  }, [tempToken, dispatch, history]);

  useEffect(() => {
    const token = get("token");
    dispatch(setLoading(true));
    if (
      isAuth === false &&
      !token &&
      tempToken === undefined &&
      userToken === undefined
    ) {
      window.location.replace(
        `${process.env.REACT_APP_ACCOUNT_SITE_URL}/login?redirect=${process.env.REACT_APP_AFFILIATE}/&app_id=8`
      );
    }
    dispatch(setLoading(false));
  }, [isAuth, tempToken, userToken, dispatch, location]);

  // useEffect(() => {
  //   if (isAuth) connectMainIo();
  // }, [isAuth]);

  const onClick = () => {
    window.location.replace(`${process.env.REACT_APP_ACCOUNT_SITE_URL}`);
  };

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          site_id: 8,
        };
        const res = await axiosAccount.post(GET_DRIFT_DATA, payload);
        if (res.status && res.data?.data?.script) {
          // window.drift.load(res.data.data.script);
        }
      } catch (err) {
        toast.error(err.response?.data?.message || err.message);
      }
    })();
  }, []);

  // const onClose = () => {
  //   const mainToken = get("mainToken");
  //   set("token", mainToken);
  //   remove("mainToken");
  //   remove("userId");
  //   remove("site");
  //   window.location.replace(process.env.REACT_APP_ADMIN_SITE_URL);
  // };

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
        limit={3}
      />
      <Layout>
        <Switch>
          {privateRoutes.map(item => (
            <Route
              key={item.path}
              path={item.path}
              exact
              render={props => {
                if (isAuth) {
                  return <item.component {...props} />;
                } else {
                  return <></>;
                }
              }}
            />
          ))}
        </Switch>
        <LiveChatWidget license="8932034" />
      </Layout>

      <Modal show={isOpen} centered backdropClassName="custom-backdrop-opacity">
        <ModalBody>
          Sorry, you are not enrolled in the affiliate program yet. Please
          contact to support team.
        </ModalBody>
        <ModalFooter>
          <Button className="theme-button" onClick={onClick}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <script>
        {`        
        _linkedin_partner_id = "1573140";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      
        (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}
      </script>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1573140&fmt=gif"
        />
      </noscript>
    </div>
  );
}

export default App;
