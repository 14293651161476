import { DISPLAY_VISIT_DATA, DISPLAY_VISIT_DATA_LOADING } from "./actionTypes";
import { FETCH_VISITS_URL } from "constants/urls";
import { axiosAdmin } from "services/api";
import { toast } from "react-toastify";

export const fetchVisit = (data, cb) => {
  return async dispatch => {
    try {
      dispatch(visitDataLoading(true));
      const res = await axiosAdmin.post(FETCH_VISITS_URL, data);
      if (res.status && res.data?.data) {
        dispatch(visitDataLoading(false));
        if (!!cb && typeof cb === "function") cb();
        dispatch(displayVisitData(res.data.data));
      }
    } catch (err) {
      toast(err.response?.data?.message || err.message);
      if (!!cb && typeof cb === "function") cb();
      dispatch(visitDataLoading(false));
    }
  };
};

export const visitDataLoading = data => ({
  type: DISPLAY_VISIT_DATA_LOADING,
  payload: data,
});

export const displayVisitData = data => ({
  type: DISPLAY_VISIT_DATA,
  payload: data,
});
