import React from "react";
import { OffcanvasBody } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Grow from "assets/images/grow.png";
import userImg from "assets/images/user-img-1.png";

import { logOutUser } from "store/actions";

const getRndInteger = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

const Loader = () => {
  return [...Array(6).keys()].map((index) => (
    <li key={index} className="placeholder-glow p-2">
      <span className="site-logo placeholder col-2"></span>
      <p className={`placeholder col-${getRndInteger(4, 7)} mb-0`}></p>
    </li>
  ));
};

const Profile = ({ isOpen, close }) => {
  const { user, sites, sitesLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const selected = (url) => {
    return window.location.origin === url;
  };

  const handleLogout = () => {
    dispatch(logOutUser());
  };
  return (
    <Offcanvas className="profile-canvas" show={isOpen} placement="end">
      <OffcanvasBody className="p-0">
        <ul className="sidebar-menu-inner">
          <div className="user">
            <img
              src={
                !!user && !!user.profile_image ? user.profile_image : userImg
              }
              alt="user-img"
              className="user-img"
            />
            <label className="close-sidebar" onClick={close}>
              <i className="fa fa-times"></i>
            </label>

            {!!user && !!user.display_name && (
              <h3 className="mtb10">{user.display_name}</h3>
            )}
            {!!user && !!user.email && (
              <p className="header-user-email">{user.email}</p>
            )}
            <div className="siderbar-btn">
              <Link
                to={{ pathname: process.env.REACT_APP_ACCOUNT_SITE_URL }}
                className="btn account-btn btn-size "
                target="_parent"
              >
                Account
              </Link>

              <Link
                to=""
                className="btn logout-btn btn-size "
                onClick={handleLogout}
              >
                Logout
              </Link>
            </div>
          </div>
          <li>
            <h5 className="heading-text">Applications</h5>
            <ul className="application-submenu">
              {sitesLoading ? (
                <Loader />
              ) : (
                sites.map((site) => (
                  <React.Fragment key={site.application_menu_id}>
                    {site.application_menu_title === 3 ? (
                      <>
                        <Link
                          target="_parent"
                          to={{ pathname: `${site.link}builder` }}
                          key={site.application_menu_title}
                        >
                          <li>
                            <img
                              className="site-logo"
                              src={!!site.icon ? site.icon : Grow}
                              alt=""
                            />
                            Message Builder
                          </li>
                        </Link>
                        <Link
                          target="_parent"
                          to={{ pathname: `${site.link}app-builder` }}
                        >
                          <li>
                            <img
                              className="site-logo"
                              src={!!site.icon ? site.icon : Grow}
                              alt=""
                            />
                            App Builder
                          </li>
                        </Link>
                      </>
                    ) : (
                      <Link target="_parent" to={{ pathname: site.link }}>
                        <li
                          className={`${!!selected(site.link) ? "active" : ""}`}
                        >
                          <img
                            className="site-logo"
                            src={!!site.icon ? site.icon : Grow}
                            alt="site logo"
                          />
                          <span>{site.application_menu_title}</span>
                        </li>
                      </Link>
                    )}
                  </React.Fragment>
                ))
              )}
              {}
            </ul>
          </li>
        </ul>
        {[1, 2].includes(user?.user_role) && (
          <div className="account-btn" style={{ padding: "20px" }}>
            <Link
              target="_parent"
              className="go-admin-btn"
              to={{ pathname: process.env.REACT_APP_ADMIN_SITE_URL }}
            >
              Go To Admin Panel
            </Link>
          </div>
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
};
export default Profile;
