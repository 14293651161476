import { lazy } from "react";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "components/dashboard")
);

const Referrals = lazy(() =>
  import(/* webpackChunkName: 'Referrals' */ "components/Referrals")
);

const Visit = lazy(() =>
  import(/* webpackChunkName: 'Visit' */ "components/visits")
);
const Payouts = lazy(() =>
  import(/* webpackChunkName: 'Payouts' */ "components/payouts")
);

const PrivateRoutes = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/referrals",
    component: Referrals,
  },
  {
    path: "/visits",
    component: Visit,
  },
  {
    path: "/payouts",
    component: Payouts,
  },
];
export default PrivateRoutes;
