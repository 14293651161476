import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import dashboardReducer from "components/dashboard/store/reducer";
import referralsReducer from "../components/Referrals/store/reducer";
import visitReducer from "components/visits/store/reducer";
import payoutReducer from "components/payouts/store/reducer";
export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  Referrals: referralsReducer,
  visit: visitReducer,
  Payouts: payoutReducer,
});
