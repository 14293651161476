import { FETCH_PAYOUT_DATA, FETCH_PAYOUT_DATA_LOADING } from "./actionTypes";

import { FETCH_PAYOUT_URL } from "constants/urls";
import { axiosAdmin } from "services/api";
import { toast } from "react-toastify";

export const fetchPayouts = (payload, cb) => {
  return async dispatch => {
    try {
      dispatch(setPayoutDataLoad(true));
      const res = await axiosAdmin.post(FETCH_PAYOUT_URL, payload);
      if (res.status && res.data?.data) {
        if (!!cb && typeof cb === "function") cb();
        dispatch(setPayoutData(res.data.data));
        dispatch(setPayoutDataLoad(false));
      }
    } catch (err) {
      if (!!cb && typeof cb === "function") cb();
      toast(err.response?.data?.message || err.message);
      dispatch(setPayoutDataLoad(false));
    }
  };
};

export const setPayoutData = data => ({
  type: FETCH_PAYOUT_DATA,
  payload: data,
});
export const setPayoutDataLoad = data => ({
  type: FETCH_PAYOUT_DATA_LOADING,
  payload: data,
});
