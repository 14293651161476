import {
  CONVERSATION_GRAPH_URL,
  GET_AFFILIATE_TOTALS_URL,
  PAID_GRAPH_URL,
  SHIPPING_ADDRESS,
  VISIT_GRAPH_URL,
} from "constants/urls";
import { toast } from "react-toastify";
import { axiosAdmin } from "services/api";
import {
  CONVERSATION_GRAPH,
  PAID_GRAPH,
  SET_AFFILIATE_TOTALS,
  VISIT_GRAPH,
} from "./actionTypes";

export const updateBillingShippingAddress = (data) => {
  return async (dispatch) => {
    try {
      const res = await axiosAdmin.post(SHIPPING_ADDRESS, data);
      if (res.status) {
      
      }
    } catch (err) {
      toast.error(err.response?.data?.message || err.message);
    }
  };
};

export const paidGraph = (data) => {
  return async (dispatch) => {
    try {
      const res = await axiosAdmin.post(PAID_GRAPH_URL, data);
      if (res.status && res.data?.data.paid) {
        dispatch(setPaidGraph(res.data.data.paid));
      }
    } catch (err) {
      toast.error(err.response?.data?.message || err.message);
    }
  };
};
export const visitGraph = (data) => {
  return async (dispatch) => {
    try {
      const res = await axiosAdmin.post(VISIT_GRAPH_URL, data);
      if (res.status && res.data?.data?.visits) {
        dispatch(setVisitGraph(res.data.data.visits));
      }
    } catch (err) {
      toast.error(err.response?.data?.message || err.message);
    }
  };
};
export const conversationGraph = (data) => {
  return async (dispatch) => {
    try {
      const res = await axiosAdmin.post(CONVERSATION_GRAPH_URL, data);
      if (res.status && res.data?.data?.totalData) {
        dispatch(setConversationGraph(res.data.data.totalData));
      }
    } catch (err) {
      toast.error(err.response?.data?.message || err.message);
    }
  };
};
export const getAffiliateTotals = (data) => {
  return async (dispatch) => {
    try {
      const res = await axiosAdmin.post(GET_AFFILIATE_TOTALS_URL, {
        ...data,
      });
      if (res.status && res.data?.data) {
        dispatch(setAffiliateTotal(res.data.data));
      }
    } catch (err) {
      toast.error(err.response?.data?.message || err.message);
    }
  };
};

export const setAffiliateTotal = (data) => ({
  type: SET_AFFILIATE_TOTALS,
  payload: data,
});
export const setVisitGraph = (data) => ({
  type: VISIT_GRAPH,
  payload: data,
});
export const setConversationGraph = (data) => ({
  type: CONVERSATION_GRAPH,
  payload: data,
});
export const setPaidGraph = (data) => ({
  type: PAID_GRAPH,
  payload: data,
});
