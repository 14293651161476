import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToggleMenu } from "store/actions";

import userImg from "assets/images/user-img-1.png";
import Profile from "./Profile";

const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);

  return (
    <div>
      {" "}
      <div className="top-nav">
        <div className="menu-toggle">
          <i
            className="fa fa-bars text-white"
            onClick={() => dispatch(setToggleMenu())}
          />
        </div>
        <div className="top-nav_right">
          <div
            className="profile-block"
            onClick={() => {
              setOpen(true);
            }}
          >
            <img
              src={user?.profile_image ? user.profile_image : userImg}
              alt=""
            />
            <span>{user?.first_name}</span>

            <i className="fas fa-chevron-down" />
          </div>
        </div>
      </div>
      <Profile isOpen={open} close={() => setOpen(false)} />
    </div>
  );
};

export default Header;
