import produce from "immer";
import {
  SET_AFFILIATE_TOTALS,
  VISIT_GRAPH,
  CONVERSATION_GRAPH,
  PAID_GRAPH,
} from "./actionTypes";

import {
  ReferralCounts,
  ConversationGraph,
  VisitGraph,
  ReferralGraph,
} from "constants/defaultGraphParameters";

const initialState = {
  affiliateTotals: ReferralCounts,
  visitGraph: VisitGraph,
  conversationGraph: ConversationGraph,
  paidGraph: ReferralGraph,
};

const dashboardReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AFFILIATE_TOTALS:
      state.affiliateTotals = payload;
      break;
    case VISIT_GRAPH:
      state.visitGraph = payload;
      break;
    case CONVERSATION_GRAPH:
      state.conversationGraph = payload;
      break;
    case PAID_GRAPH:
      state.paidGraph = payload;
      break;

    default:
      return state;
  }
});

export default dashboardReducer;
