export const SET_ERROR = "SET_ERROR";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_TOKEN = "SET_TOKEN";

export const SET_TOKEN_LOADING = "SET_TOKEN_LOADING";
export const SET_TOKEN_ERROR = "SET_TOKEN_ERROR";
export const SET_PROFILE = "SET_PROFILE";

export const SET_IS_AUTH = "SET_IS_AUTH";

export const SET_SITES_LOADING = "SET_SITES_LOADING";
export const PROFILE_LOADING = "PROFILE_LOADING";

export const TOGGLE_MENU = "TOGGLE_MENU";

export const VERIFY_AFFILIATE_USER = "VERIFY_AFFILIATE_USER";
export const SET_VERIFY_AFFILIATE_USER = "SET_VERIFY_AFFILIATE_USER";

export const SET_MENUS = "SET_MENUS";
