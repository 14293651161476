import produce from "immer";
import {
  SET_ERROR,
  SET_PROFILE,
  LOGOUT,
  SET_LOADING,
  SET_TOKEN,
  SET_IS_AUTH,
  // SET_SITES,
  SET_SITES_LOADING,
  PROFILE_LOADING,
  TOGGLE_MENU,
  SET_VERIFY_AFFILIATE_USER,
  SET_MENUS,
} from "./actionTypes";

const initialState = {
  user: null,
  token: "",
  error: "",
  loading: false,
  isAuth: false,
  sites: [],
  sitesLoading: false,
  profileLoading: false,
  toggleMenu: false,
  affiliateUser: {
    is_affiliate_user: 1,
    affiliate_code: "",
  },
  sideMenus: [],
  footerMenus: [],
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ERROR:
      state.error = payload;
      break;
    case SET_PROFILE:
      state.user = payload;
      state.isAuth = true;
      break;
    case SET_TOKEN:
      state.token = payload;
      break;
    case LOGOUT:
      state.user = null;
      state.token = "";
      state.isAuth = false;
      break;
    case SET_LOADING:
      state.loading = payload;
      break;
    case SET_IS_AUTH:
      state.isAuth = payload;
      break;
    case SET_SITES_LOADING:
      state.sitesLoading = payload;
      break;
    case PROFILE_LOADING:
      state.profileLoading = payload;
      break;
    case TOGGLE_MENU:
      state.toggleMenu = !state.toggleMenu;
      break;
    case SET_VERIFY_AFFILIATE_USER:
      state.affiliateUser = payload;
      break;
    case SET_MENUS:
      let stateMenu = "";
      if (payload.menuType === 3) {
        stateMenu = "sideMenus";
      } else if (payload.menuType === 2) {
        stateMenu = "footerMenus";
      } else if (payload.menuType === 4) {
        stateMenu = "sites";
      }
      state[stateMenu] = payload.data;
      break;
    default:
      return state;
  }
});

export default authReducer;
