import produce from "immer";
import { DISPLAY_REFERRALS, DISPLAY_REFERRALS_LOADING } from "./actionTypes";
const initialState = {
  loading: false,
  referrals: [],
  count: 0,
};

const referralsReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISPLAY_REFERRALS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case DISPLAY_REFERRALS:
      return {
        ...state,
        referrals: payload?.rows || [],
        count: payload?.count || 0,
      };

    default:
      return state;
  }
});

export default referralsReducer;
