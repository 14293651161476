export const ACCESS_TOKEN_URL = "/ssoAuth";
export const GET_PROFILE_INFO = "/getUserProfileDetail";
export const FETCH_SITES = "/listSite";
export const VERIFY_AFFILIATE_USER_URL = "/verifyAffiliateUser";
export const GET_AFFILIATE_TOTALS_URL = "/getAffiliateCounts";
export const VISIT_GRAPH_URL = "/getVisitLineGraph";
export const CONVERSATION_GRAPH_URL = "/getConversationRateGraph";
export const PAID_GRAPH_URL = "/getPaidGraph";
export const FETCH_MENUS_URL = "/listAllApplicationMenu";

export const List_REFERRAL_DATA = "/listAffiliateReferral";
export const FETCH_VISITS_URL = "/listAffiliatesVisitData";

export const FETCH_PAYOUT_URL = "/listAffiliatePayout";

export const GET_DRIFT_DATA = "/getDriftCodeBySiteId";
export const LOG_OUT_USER = "/logOutUser";

export const GET_STATES = "/listState";
export const GET_COUNTRIES = "/listCountry";

export const SHIPPING_ADDRESS = "/updateBillingShippingAddressDetail";
